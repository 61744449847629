import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Authorization flows`}</h2>
    <p>{`The OAuth 2.0 protocol provides several workflows for authorizing a user and obtaining an access token. It depends on the type and architecture of the client which flow is the most suitable. The following workflows are supported:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Authorization Code:`}</strong>{` Suitable for third-party websites which contain a client and a server component.`}</li>
      <li parentName="ul"><strong parentName="li">{`Client Credentials:`}</strong>{` Best choice for users developing server-side applications to manage their content or settings.`}</li>
    </ul>
    <h3>{`Authorization Code flow`}</h3>
    <ol>
      <li parentName="ol">{`The user enters credentials to a secure login webpage.`}</li>
      <li parentName="ol">{`After logging in, the browser is redirected to a special URL (defined by the client), passing an authorization code in the URL.`}</li>
      <li parentName="ol">{`The third-party server obtains the access token with another HTTP request in the background, using the authorization code.`}</li>
    </ol>
    <p>{`See the OAuth 2.0 Authorization Framework (`}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc6749"
      }}>{`https://tools.ietf.org/html/rfc6749`}</a>{`) for details.`}</p>
    <h3>{`Client Credentials flow`}</h3>
    <p>{`Client credentials can be generated on the Video Streaming dashboard. The client obtains an access token using the `}<inlineCode parentName="p">{`client_id`}</inlineCode>{` and `}<inlineCode parentName="p">{`client_secret`}</inlineCode>{`. With this token the user’s server-side application can access the resources of the account which is identified by this client_id/client_secret pair.`}</p>
    <h2>{`Authorizing the user`}</h2>
    <Title mdxType="Title">Authorization endpoint</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://authentication.video.ibm.com/authorize"
            }}>{`https://authentication.video.ibm.com/authorize`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Supported HTTP methods`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GET, POST`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Supported flows`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authorization Code`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The authorization endpoint is a secure web page that authenticates the user. The client should show this page to the user in an embedded browser. When the user completes the login process the browser is redirected to a special URL. The client can capture this redirect call and obtain an authorization code.`}</p>
    <h3>{`Parameters`}</h3>
    <p>{`The parameters below specify the behavior of the authorization endpoint. These have to be set in GET or POST HTTP parameters.`}</p>
    <Title mdxType="Title">Supported OAuth 2.0 standard parameters</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`response_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`code`}</inlineCode>{` for Authorization Code flow`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40-character long string that identifies the client. Provided by IBM Video Streaming.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`redirect_uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URI where the browser will redirect after the authentication process. This URI must be registered at IBM Video Streaming.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`state`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This value is sent as a GET parameter to the redirect_uri to maintain state between the request and callback. See the OAuth 2.0 Authorization Framework (`}<a parentName="td" {...{
              "href": "https://tools.ietf.org/html/rfc6749"
            }}>{`https://tools.ietf.org/html/rfc6749`}</a>{`) for details.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`scope`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whitelist-separated list of the requested scopes.`}</td>
        </tr>
      </tbody>
    </table>
    <Title mdxType="Title">IBM Video Streaming specific extra parameters</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`device_name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Full product name of the client device or application. It is used for easy identification of the OAuth 2.0 token. The user will be able to review which clients are connected to his/her account, and revoke this access on the IBM Video Streaming website.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lang`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show the authorization page in this locale (e.g. en_US); This parameter will also set the language of the newly registered users.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Response values`}</h3>
    <p>{`The response values below are appended to the redirect_uri as HTTP GET parameters.`}</p>
    <h4>{`Authorization Code flow`}</h4>
    <p>{`If the `}<inlineCode parentName="p">{`response_type`}</inlineCode>{` parameter is `}<inlineCode parentName="p">{`code`}</inlineCode>{`, the following properties are returned:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PROPERTY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An authorization code (40-character long string)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`state`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If state was sent to the endpoint, this information is`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Error handling`}</h3>
    <p>{`If the authentication was not successful, there is no HTTP redirection: the user can stay on the Authorization Endpoint page until the correct credentials are entered. It is also possible to request a password reset if the password is forgotten.`}</p>
    <p>{`If the user authenticates correctly, but interrupts the authorization flow, e.g. by pressing a Deny button on the authorization page, the browser is redirected to the redirect_uri with the following parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`NAME`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`access_denied`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If state was sent to the endpoint, this information is sent back`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Obtain an access token`}</h2>
    <Title mdxType="Title">Token Endpoint</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://video.ibm.com/oauth2/token"
            }}>{`https://video.ibm.com/oauth2/token`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Supported HTTP methods`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`POST`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Authorization Code flow`}</h3>
    <p>{`When the client receives the authorization code the server-side component of the client calls the token endpoint to return the access token.`}</p>
    <h4>{`Parameters`}</h4>
    <p>{`The following parameters must be set as HTTP POST parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`grant_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MUST be `}<inlineCode parentName="td">{`authorization_code`}</inlineCode>{` in this case.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40-character long string, provided by IBM Video Streaming`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The authorization code received from the authorization endpoint`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`redirect_uri`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The redirection URI used by the authorization server to return the authorization response in the previous step`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`In addition to the parameters above, the client must provide its client secret (provided along with the client key) to authenticate itself. The authentication is done with HTTP Basic authorization method.`}</p>
    <p>{`Example HTTP header:`}</p>
    <pre><code parentName="pre" {...{}}>{`Authorization: Basic bc345abc45d6789abcdef0123aef0126789def01
`}</code></pre>
    <h4>{`Success response`}</h4>
    <p>{`The response of the Token Endpoint is a JSON object.`}</p>
    <p>{`JSON response upon success:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`NAME`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This property is only returned if your request included an openid scope. The value is a JSON Web Token (JWT) that contains digitally signed identity information about the user.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`access_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Access token (40-character long string)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`refresh_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A token that you can use to obtain a new access token`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`token_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Will be Bearer Token`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`expires_in`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Token’s current lifetime, in seconds.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Example`}</h4>
    <p>{`The following is an example of the authorization code flow.`}</p>
    <p>{`1 - The client opens a browser with the authorization endpoint:`}</p>
    <pre><code parentName="pre" {...{}}>{`https://authentication.video.ibm.com/authorize
?response_type=code
&client_id=AAAAAAAAAABBBBBBBBBBCCCCCCCCCCDDDDDDDDDD
&redirect_uri=http://example.com/get_access_token
&device_name=My%20Device
&scope=broadcaster
&state=XYZ
`}</code></pre>
    <p>{`2 - The user enters his/her credentials and presses the Allow button. The browser is redirected to the following URL:`}</p>
    <pre><code parentName="pre" {...{}}>{`http://example.com/get_access_token?code=19d8dbb3ebac55f110c3b526e38bcfdfbf46d659&state=XYZ
`}</code></pre>
    <p>{`3 - The page handler at `}<a parentName="p" {...{
        "href": "http://example.com/get_access_token"
      }}>{`http://example.com/get_access_token`}</a>{` retrieves the Access Token using the Token Endpoint:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST /oauth2/token HTTP/1.1
    Host: video.ibm.com
    Authorization: Basic xxxxxxxxxxyyyyyyyyyywwwwwwwwwwzzzzzzzzzz
    Content-Type: application/x-www-form-urlencoded
grant_type=authorization_code&client_id=AAAAAAAAAABBBBBBBBBBCCCCCCCCCCDDDDDDDDDD
    &code=19d8dbb3ebac55f110c3b526e38bcfdfbf46d659&redirect_uri=http://example.com/get_access_token

`}</code></pre>
    <p>{`4 - The response of token endpoint contains the access token:`}</p>
    <pre><code parentName="pre" {...{}}>{`HTTP/1.1 200 OK
    Cache-Control: no-store
    Content-Type:application/json; charset=UTF-8
    {"access_token":"ab345cdef123ef1267890abcdef04567890abcd1", "refresh_token":"cb12cdef123ef1267890abcdef04567890abcd1","token_type":"bearer", "expires_in":86400}

`}</code></pre>
    <h3>{`Client Credentials flow`}</h3>
    <h4>{`Parameters`}</h4>
    <p>{`The following parameters must be set as HTTP POST parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`grant_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MUST be `}<inlineCode parentName="td">{`client_credentials`}</inlineCode>{` in this case.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40-character long string, provided by IBM Video Streaming.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`device_name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`device name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`scope`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`broadcaster`}</inlineCode>{` for broadcaster scope, or space separated URL encoded list of these strings`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`In addition to the parameters above, the client must provide its client secret (provided along with the client key) to authenticate itself. The authentication is done with HTTP Basic authorization method.`}</p>
    <p>{`Example HTTP header:`}</p>
    <pre><code parentName="pre" {...{}}>{`Authorization: Basic bc345abc45d6789abcdef0123aef0126789def01
`}</code></pre>
    <Title mdxType="Title">Success response</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`NAME`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This property is only returned if your request included an openid scope. The value is a JSON Web Token (JWT) that contains digitally signed identity information about the user.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`access_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Access token (40-character long string)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`refresh_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A token that you can use to obtain a new access token`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`token_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Will be bearer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`expires_in`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Token’s current lifetime, in seconds.`}</td>
        </tr>
      </tbody>
    </table>
    <Title mdxType="Title">Possible error responses</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_client`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The referred client is missing, the secret is wrong or the authorization requester client and token requester client does not match.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_grant`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The supplied authorization_code does not exist or expired`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_request`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One or more required parameters are missing`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`501 Not Implemented`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`unsupported_grant_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The client asks for an unsupported grant type, currently only `}<inlineCode parentName="td">{`authorization_code`}</inlineCode>{`, `}<inlineCode parentName="td">{`client_credentials`}</inlineCode>{` and `}<inlineCode parentName="td">{`refresh_token`}</inlineCode>{` are supported.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Server Error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`server_error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The server cannot handle the request at this moment for some reason. Try again later.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Refreshing access tokens`}</h2>
    <p>{`When access tokens expire or become invalid, applications can obtain a new access token using a refresh token without prompting users to enter their login credentials again. The refresh token is returned as a response of one of the previous token requests. Because refresh tokens are last for 30 days, developers should ensure that strict storage requirements are in place to keep them from being leaked.`}</p>
    <h4>{`Parameters`}</h4>
    <p>{`The following parameters must be set as HTTP POST parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`grant_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MUST be `}<inlineCode parentName="td">{`refresh_token`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`refresh_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A token that you can use to obtain a new access token. Refresh tokens are valid for 30 days or until the user revokes access.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Client id provided by IBM Video Streaming.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`In addition to the parameters above, the client must provide its client secret (provided along with the client key) to authenticate if the client acts as a “web application”. Sending client secret can be omitted in case of “native application”.
The authentication is done with HTTP Basic authorization method.`}</p>
    <p>{`Example HTTP header:`}</p>
    <pre><code parentName="pre" {...{}}>{`Authorization: Basic bc345abc45d6789abcdef0123aef0126789def01
`}</code></pre>
    <h4>{`Success response`}</h4>
    <p>{`The response of the Token Endpoint is a JSON object.`}</p>
    <p>{`JSON response upon success are:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`NAME`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This property is only returned if your request included an openid scope. The value is a JSON Web Token (JWT) that contains digitally signed identity information about the user.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`access_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Access token (40-character long string)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`refresh_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A token that you can use to obtain a new access token`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`token_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Will be Bearer Token`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`expires_in`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Token’s current lifetime, in seconds.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Example`}</h4>
    <p>{`Previous token endpoint response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "access_token": "ab345cdef123ef1267890abcdef04567890abcd1",
  "refresh_token": "cb345cdef123ef1267890abcdef04567890abcd1",
  "token_type": "bearer",
  "expires_in": 86400
}
`}</code></pre>
    <p>{`Refresh token request:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST /oauth2/token HTTP/1.1
Host: video.ibm.com
Authorization: Basic xxxxxxxxxxyyyyyyyyyywwwwwwwwwwzzzzzzzzzz
Content-Type: application/x-www-form-urlencoded

grant_type=refresh_token&client_id=AAAAAAAAAABBBBBBBBBBCCCCCCCCCCDDDDDDDDDD&refresh_token=cb345cdef123ef1267890abcdef04567890abcd1
`}</code></pre>
    <p>{`Refresh token response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "access_token": "db345cdef123ef1267890abcdef04567890abcd1",
  "refresh_token": "eb345cdef123ef1267890abcdef04567890abcd1",
  "token_type": "bearer",
  "expires_in": 86400
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      